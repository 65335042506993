<template>
  <div class="main">
    <div class="main__background">
        <img class="main__background--img" src="../../assets/textures/amarillo.png" alt="paint" />
    </div>
    <div class="main__header">
        <h2 class="main__header--title">CONTÁCTAME</h2>
        <p class="main__header--subtitle">ZAIRA BANDY</p>
    </div>
    <div class="form">
        <form action="/colab-mail" method="POST">
            <div class="first__section">
                <h2>DATOS DE CONTACTO</h2>
                <br>
                <div class="form__group">
                    <label for="name">NOMBRE COMPLETO</label>
                    <input type="text" name="name" placeholder="ZAIRA BANDY" autofocus>
                </div>
                <br>
                <div class="form__group">
                    <label for="email">CORREO ELECTRÓNICO</label>
                    <input type="mail" name="email" placeholder="ZAIRABANDY@GMAIL.COM" autofocus>
                </div>
                <br>
                <div class="form__group">
                    <label for="phone">TELÉFONO</label>
                    <input type="phone" name="phone" placeholder="55-42-40-60-20" autofocus>
                </div>
            </div>
            <div class="second__section">
                <h2>¿EN QUÉ TE PUEDO AYUDAR?</h2>
                <br>
                <div class="form__group">
                    <label for="area">ÁREA</label>
                    <select v-model="area" name="area">
                        <option disabled value="">Seleccione un área</option>
                        <option>PINTURA</option>
                        <option>FOTOGRAFÍA</option>
                    </select>
                </div>
                <br>
                <div class="form__group" v-if="area == 'PINTURA'">
                    <label for="tipo">TIPO</label>
                    <select v-model="type" name="tipo">
                        <option disabled value="">Seleccione un área</option>
                        <option>PAISAJES</option>
                        <option>ANIMALES</option>
                        <option>COMISIÓN PERSONALIZADA</option>
                    </select>
                </div>
                <br>
                <div class="form__group--text">
                    <label for="message">DÉJAME UN MENSAJE</label>
                    <textarea name="message" id="message" cols="30" rows="10" placeholder="HOLA, ME GUSTARÍA..."></textarea>
                </div>
                <btn-prim class="btn__send">
                    <template v-slot:text>
                        ENVIAR CORREO
                    </template>
                </btn-prim>
            </div>
        </form>
    </div>
    <div class="footer">
        <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../../components/Footer.vue';
import BtnPrim from "../../components/Buttons/BtnPrim.vue"

export default {
    data() {
        return {
            area: '',
            type: ''
        }
    },
    components: {
        Footer,
        BtnPrim
    }
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}

/** FORM */
form {
    width: auto;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: -100px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 100px;
    background: var(--bk-color);
}
.first__section{
    padding: 50px;    
}
.second__section{
    padding: 50px;
}
.form__group{
    display: grid;
}
label {
    padding: 15px 0px;
}
input, select, textarea {
    width: 400px;
    padding: 15px;
    font-size: 15px;
    font-family: inherit;
}
.form__group--text{
    display: grid;
}
.btn__send{
    margin: 50px 0px;
    text-align: end;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 40px;
  }
  form {
      padding: 20px;
      margin-left: 20px;
      margin-right: 20px;
  }
  input, select, textarea {
      width: auto;
  }
}
</style>